import React, { Component } from 'react';

class Newsletter extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div className="newsletter">
                <div className="newsletter-container">
                    <h3 className="newsletter-title">Join our mailing list to stay up to date!</h3>
                        <div id="mc_embed_signup">
                        <form action="https://featsapp.us10.list-manage.com/subscribe/post?u=e76b433f7dcf9996ca2616b74&amp;id=d6e94560b1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                            	<input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Email Address" required/>
                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                                    <input type="text" name="b_e76b433f7dcf9996ca2616b74_d6e94560b1" tabindex="-1" value=""/>
                                </div>
                                <div class="clear">
                                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/>
                                </div>
                            </div>
                        </form>
                        </div>
                </div>
            </div>
        );
    }
}

export default Newsletter;
