import React, { Component } from 'react';

class Footer extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-social">
                        <a href="https://www.instagram.com/seetaller" target="_blank"><div className="footer-social-item social-instagram"></div></a>
                        <a href="https://www.facebook.com/seetaller" target="_blank"><div className="footer-social-item social-facebook"></div></a>
                        <a href="https://www.youtube.com/seetaller" target="_blank"><div className="footer-social-item social-youtube"></div></a>
                        <a href="https://www.twitter.com/seetaller" target="_blank"><div className="footer-social-item social-twitter"></div></a>
                    </div>
                    <div className="footer-legal">All rights reserved</div>
                </div>
            </div>
        );
    }
}

export default Footer;
