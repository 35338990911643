import React, { Component } from 'react';

class Welcome extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div className="welcome">
                <div className="welcome-container">
                    <div className="welcome-left">
                        <div className="welcome-left-logo">
                            <div className="welcome-left-logo-image"></div>
                            <h2 className="welcome-left-logo-title">Feats</h2>
                        </div>
                        <div className="welcome-left-content">
                            <h3>Join a community</h3>
                            <h3>of achievers</h3>
                            <p>Tackle new skills, track your progress, and review your accomplishments.</p>
                        </div>
                        <div className="welcome-left-download">
                            <a href="https://apps.apple.com/us/app/feats-achievement-tools/id1509342652" target="_blank"><img className="welcome-left-download-item welcome-left-download-item-left" alt="Download" src="https://d2beia7gtp5yjy.cloudfront.net/cdn_image/df/38d11/33250465/24612/landing/v999/nd4p9/app-store-badge_2x.png"/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.badges&hl=en" target="_blank"><img className="welcome-left-download-item welcome-left-download-item-right" alt="Download" src="https://d2beia7gtp5yjy.cloudfront.net/cdn_image/df/ffe58/33250465/24612/landing/v999/ktfk5/imgbin-google-play-app-store-android-jal-jeera-KsL3mg0YXPmE1RmS5kCX9SPAq.png"/></a>
                        </div>
                    </div>
                    <div className="welcome-right">
                        <img className="welcome-right-image" alt="Product" src="https://d2beia7gtp5yjy.cloudfront.net/cdn_image/df/4a118/33250465/24612/landing/v999/e3ewa/OB1.png"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Welcome;
