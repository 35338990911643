import React, { Component } from 'react';
import Welcome from './landing/Welcome.jsx';
import Newsletter from './landing/Newsletter.jsx';
import Header from './Header.jsx';
import Footer from './Footer.jsx';

class Landing extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div>
                <Welcome/>
                <Newsletter/>
                <Footer/>
            </div>
        );
    }
}

export default Landing;
