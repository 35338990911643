import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div className="header">
                <div className="header-menu">
                    <Link style={{ textDecoration: 'none', color: 'black', margin: '0 25px' }} to='/'><div>Home</div></Link>
                    <Link style={{ textDecoration: 'none', color: 'black', margin: '0 25px' }} to='/About'><div>About</div></Link>
                </div>
            </div>
        );
    }
}

export default Header;
