import React, { Component } from 'react';
import Header from './Header';

class About extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }
    render() {
        return (
            <div className="about">
                <h4 className="about-title">About Us:</h4>
                <p className="about-text">Feats is on a mission to help you become a more interesting human. Happier. Smarter. More Talented. More Educated. We’re not just an app. We are a community of achievers. Curious people learning how to do new and interesting things.</p>
                <h4 className="about-title">How it works:</h4>
                <p className="about-text">Feats allows you to select goals (feats) from our achievement library and complete them to earn badges. Each feat has educational materials, instructions, and a set of requirements. Learn the materials, put in the work, track your progress in the app and you’ll be earning badges in no time. A more interesting you awaits.</p>
                <h4 className="about-title">Our community:</h4>
                <p className="about-text">Feats introduces a new goal for our community every month. Each feat is different. You may be learning to skateboard, making apple pie, memorizing poetry, fixing a flat tire, throwing a perfect spiral, or countless other achievements. Get motivational updates, reminders, and see how the rest of the community is doing on our focus goal in our community. Let us walk with you as you climb the trail of interesting feats.</p>
                <p className="about-text">Feats is built on the idea that we as humans are the most fulfilled when we are on the path to achieving our goals. Great humans aren’t born, they are made through great feats. Come achieve with us.</p>
            </div>
        );
    }
}

export default About;
