import React from 'react';
import './Reset.css';
import './App.css';
import routes from './routes.js';
import Header from './components/Header.jsx';

function App() {
  return (
    <div className="App">
        <Header/>
        {routes}
    </div>
  );
}

export default App;
