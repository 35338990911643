import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './components/Landing';
import About from './components/About';

export default (
    <Switch>
        <Route exact path='/' component={Landing} />
        <Route path='/about' component={About} />
    </Switch>
)
